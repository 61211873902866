<template>
    <div>积分排行</div>
    </template>
     
     <script>
  
    export default {
      components: {},
      data() {
        return {
  
        };
      },
    
      methods: {},
    
      created() {},
    
      mounted() {},
    };
    </script>
     <style lang='less' scoped>
    </style>